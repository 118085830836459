import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network, mysteryKey } from 'config';
import { getSmartContractObj } from 'contexts/scMysteryKeysRequests';
import { Address, Transaction, TransactionWatcher, U64Value, TokenTransfer, BigUIntValue } from '@multiversx/sdk-core/out';
import { TransactionsDisplayInfoType } from '@multiversx/sdk-dapp/types';
import { sendAndSignTransactions } from './useSendAndSign';
import BigNumber from 'bignumber.js';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';

const watcher = new TransactionWatcher(new ApiNetworkProvider(network.apiAddress), { patienceMilliseconds: 8000 });

const sendAndSignTransactionsWrapped = async (
    transactions: Transaction[],
    displayInfo: TransactionsDisplayInfoType
): Promise<{
    success: boolean;
    error: string;
    sessionId: string | null;
}> => {
    const result = await sendAndSignTransactions(transactions, displayInfo);
    await watcher.awaitCompleted(transactions[0]);
    return result;
};

export const useMysteryUpdateKeyPrice = (price: number) => {
    const { account, address } = useGetAccountInfo();

    const displayInfo = {
        processingMessage: 'Processing update key price transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Key price updated successfully'
    };

    const  process = async () => {
        const contract = await getSmartContractObj();
        const interaction = contract.methods.updatePrice([1, price]);

        const transaction = interaction
            .withNonce(account.nonce)
            .withValue(0)
            .withSender(new Address(address))
            .withGasLimit(12_000_000)
            .withChainID(network.chainId)
            .buildTransaction();
        const sessionId = await sendAndSignTransactionsWrapped(
            [transaction],
            displayInfo
        );
        return sessionId;
    };

    return process;
};
