import styles from '../raffle.module.scss';
import Countdown from 'react-countdown';

export default function MiniTimer({ startTimestamp }:{ startTimestamp: number}) {

	// build the countdown timer
	const renderer = ({ days, hours, minutes, seconds, completed }: {days: any, hours: any, minutes: any, seconds: any, completed: boolean}) => {
		if (completed) {
			return (
				<div className="text-center">
          <p className='text-center h5'>This raffle has ended</p>
					<div className='d-flex justify-content-around'>
            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(0).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>&nbsp;Days&nbsp;&nbsp;</p>
            </div>

            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(0).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>&nbsp;Hours&nbsp;</p>
            </div>

            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(0).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>Minutes</p>
            </div>

            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(0).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>Seconds</p>
            </div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="text-center">
          <p className='text-center h5'>This raffle ends in:</p>
					<div className='d-flex justify-content-around'>
            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(days).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>&nbsp;Days&nbsp;&nbsp;</p>
            </div>

            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(hours).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>&nbsp;Hours&nbsp;</p>
            </div>

            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(minutes).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>Minutes</p>
            </div>

            <div className='px-1'>
              <p className={`${styles.miniTimerDigits}`}>{String(seconds).padStart(2, '0')}</p>
              <p className='small' style={{marginTop: '-20px'}}>Seconds</p>
            </div>
					</div>
				</div>
			);
		}
	};

	return (
		<Countdown renderer={renderer} date={startTimestamp} autoStart/>
	);
}