import { useState, useEffect } from 'react';
import { useRaffleCreateRound } from 'contexts/hooks/transactions/useRaffleCreateRound';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import toast, {Toaster} from 'react-hot-toast';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { getDatabase, ref as databaseRef, set, remove, get } from 'firebase/database';

const getAllRafflePrizes = async (): Promise<any[]> => {
  const database = getDatabase();

  try {
    const rafflePrizesRef = databaseRef(database, 'rafflePrizes');
    const snapshot = await get(rafflePrizesRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      const prizesArray = Object.keys(data).map(key => data[key]);
      return prizesArray;
    } else {
      console.log('No raffle prizes found');
      return [];
    }
  } catch (error) {
    console.error('Error fetching raffle prizes:', error);
    return [];
  }
};

interface Prize {
  type: 'tokens' | 'sfts';
  identifier: string;
  amount: number;
  nonce: number;
  imageFile?: File;
}

export const RaffleCreateContractDetails = () => {
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);

  const handleStartTimeChange = (date: Date) => {
    if(date){
      const timestampInSeconds = Math.floor(date.getTime() / 1000);
      setStartTime(timestampInSeconds);
    }
  };

  const handleEndTimeChange = (date: Date) => {
    if(date){
      const timestampInSeconds = Math.floor(date.getTime() / 1000);
      setEndTime(timestampInSeconds);
    }
  };

  // build the prizes array
  const [numberOfPrizes, setNumberOfPrizes] = useState<number>(0);
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [transfers, setTransfers] = useState<any[]>([]);

  useEffect(() => {
    if (numberOfPrizes > prizes.length) {
      const newPrizes = [...prizes];
      for (let i = prizes.length; i < numberOfPrizes; i++) {
        newPrizes.push({ type: 'tokens', identifier: '', amount: 0, nonce: 0, imageFile: undefined });
      }
      setPrizes(newPrizes);
    } else {
      setPrizes(prizes.slice(0, numberOfPrizes));
    }
  }, [numberOfPrizes]);

  const handlePrizeChange = (index: number, field: keyof Prize, value: string | number | File) => {
    const updatedPrizes = [...prizes];
    updatedPrizes[index] = {
      ...updatedPrizes[index],
      [field]: value,
    };
    setPrizes(updatedPrizes);
  };

  const handleSubmit = async () => {
    const storage = getStorage(); // Initialize Firebase Storage
    const database = getDatabase(); // Initialize Firebase Database
    resetPrizeImages();
  
    for (let i = 0; i < prizes.length; i++) {
      const prize = prizes[i];
      let imageUrl = '';
  
      if (prize.imageFile) {
        const imageRef = storageRef(storage, `rafflePrizes/prize${i + 1}`);
        await uploadBytes(imageRef, prize.imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }
  
      const prizeRef = databaseRef(database, `rafflePrizes/prize${i + 1}`);
      await set(prizeRef, {
        type: prize.type,
        identifier: prize.identifier,
        amount: prize.amount,
        nonce: prize.nonce,
        imageUrl: imageUrl || null,
      });
    }
  
    const newTransfers = prizes.map(prize => {
      if (prize.type === 'sfts') {
        return TokenTransfer.semiFungible(prize.identifier, prize.nonce, prize.amount);
      } else if (prize.type === 'tokens') {
        return TokenTransfer.fungibleFromAmount(
          prize.identifier,
          prize.amount.toLocaleString('en-US', { useGrouping: false }),
          prize.nonce
        );
      }
      return null;
    }).filter(transfer => transfer !== null);
  
    setTransfers(newTransfers);
    toast.success('Prizes set successfully', { duration: 5000 });
  };

  const resetPrizeImages = async () => {
    const storage = getStorage();
    const database = getDatabase();
  
    try {
      // Step 1: Delete all files under the rafflePrizes folder in Firebase Storage
      const folderRef = storageRef(storage, 'rafflePrizes');
      const listResult = await listAll(folderRef);
  
      const deletePromises = listResult.items.map((fileRef) => deleteObject(fileRef));
      await Promise.all(deletePromises);
  
      // Step 2: Remove the rafflePrizes node from the Firebase Realtime Database
      const rafflePrizesRef = databaseRef(database, 'rafflePrizes');
      await remove(rafflePrizesRef);
  
      toast.success('Raffle prizes reset successfully', { duration: 5000 });
    } catch (error) {
      console.error('Error resetting raffle prizes:', error);
      toast.error('Failed to reset raffle prizes', { duration: 5000 });
    }
  };

  return (
    <>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mb-2'>
        <div className='card-header'>Raffle - Next Round Config</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <strong>Start Time</strong>
            <span>
              <DatePicker
                selected={startTime ? new Date(startTime * 1000) : null}
                onChange={(date: any) => handleStartTimeChange(date as Date)}
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm:ss"
                timeFormat="HH:mm:ss"
                className="form-control"
              />
            </span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>End Time</strong>
            <span>
              <DatePicker
                selected={endTime ? new Date(endTime * 1000) : null}
                onChange={(date: any) => handleEndTimeChange(date as Date)}
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm:ss"
                timeFormat="HH:mm:ss"
                className="form-control"
              />
            </span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Number of Prizes</strong>
            <span>
              <input
                type="number"
                id="numberOfPrizes"
                className="form-control"
                value={numberOfPrizes}
                onChange={e => setNumberOfPrizes(parseInt(e.target.value, 10))}
                min="0"
              />
            </span>
          </div>          

          {prizes.map((prize, index) => (
            <div key={index}>
              <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>  
              <div className="prize-form mt-2">
                <strong>Prize {(index+1).toString()}</strong>
                <div className='d-flex justify-content-between align-items-center'>
                  <u className='mt-2'>Prize Type</u>
                  <span>
                    <select value={prize.type} onChange={e => handlePrizeChange(index, 'type', e.target.value)}>
                      <option value="tokens">Tokens</option>
                      <option value="sfts">SFTS / NFTS</option>
                    </select>
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <u>Identifier</u>
                  <span>
                    <input
                      type="text"
                      placeholder="Identifier"
                      value={prize.identifier}
                      onChange={e => handlePrizeChange(index, 'identifier', e.target.value)}
                    />
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  {prize.type === 'tokens' && (<u>Decimals</u>)}
                  {prize.type === 'sfts' && (<u>Nonce</u>)}
                  <span>
                    <input
                      type="number"
                      placeholder={prize.type === 'tokens' ? 'Decimals' : 'Nonce'}
                      value={prize.nonce}
                      onChange={e => handlePrizeChange(index, 'nonce', parseInt(e.target.value, 10))}
                      min="0"
                    />
                  </span>                
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <u>Amount</u>
                  <span>
                    <input
                      type="number"
                      placeholder="Amount"
                      value={prize.amount}
                      onChange={e => handlePrizeChange(index, 'amount', parseFloat(e.target.value))}
                      min="0"
                    />
                  </span>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <u>Image</u>
                  <span>
                    <input
                      type="file"
                      onChange={e => e.target.files ? handlePrizeChange(index, 'imageFile', e.target.files[0]) : ''}
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <button className='btn btn-primary' onClick={handleSubmit}>
            1. Set Prizes
          </button>
          <button className='btn btn-primary' onClick={useRaffleCreateRound(numberOfPrizes, startTime, endTime, transfers)}>
            2. Create New Raffle
          </button>
        </div>
      </div>

      <Toaster
        toastOptions={{
          position: 'top-right',          
          style: {
            padding: '16px',
            color: '#fff',
            background: '#333',
          },
          error: {
            style: {
              border: '1px solid red',
            },
          },
          success: {
            style: {
              border: '1px solid green',
            },
          },
        }}
      />
    </>
  );
};
