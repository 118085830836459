import { dAppName } from 'config';
import { TestRoute } from 'pages/TestRoute';
import { RouteType } from 'types';
import { withPageTitle } from './components/PageTitle';
import { Admin } from './pages/Admin';
import { AdminLottery } from './pages/AdminLottery';
import { LotteryPage } from 'pages/Lottery';
import { Faq } from './pages/Faq';
import {History} from './pages/History';
import { MysteryBox } from './pages/MysteryBox/MysteryBox';
import { AdminMystery } from 'pages/AdminMystery/Admin';
import { AdminDatabase } from 'pages/AdminDatabase/Admin';
import { MyAccount } from 'pages/MyAccount';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Raffle } from 'pages/Raffle/Raffle';
import { AdminRaffle } from 'pages/AdminRaffle/Admin';

import { Home } from './pages';

export const routeNames = {
  home: '/',
  dashboard: '/dashboard',
  unlock: '/unlock',
  testRoute: '/auction',
  lottery: '/lottery',
  faq: '/faq',
  raffle: '/raffle',
  adminAuction: '/admin-auction',
  adminLottery: '/admin-lottery',
  mysteryBox: '/mystery-box',
  adminMystery: '/admin-mystery',
  adminDatabase: '/admin-database',
  adminRaffle: '/admin-raffle',
  myAccount: '/my-account'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.unlock,
    title: 'Unlock',
    component: Home
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Dashboard
  },
  {
    path: routeNames.home,
    title: 'Home',
    component: MysteryBox
  },
  {
    path: routeNames.testRoute,
    title: 'Auction House Page',
    component: TestRoute
  },
  {
    path: routeNames.lottery,
    title: 'Lottery Page',
    component: LotteryPage
  },
  {
    path: routeNames.faq,
    title: 'Faq Page',
    component: Faq
  },
  {
    path: routeNames.mysteryBox,
    title: 'Mystery Box Page',
    component: MysteryBox
  },
  {
    path: routeNames.raffle,
    title: 'Raffle Page',
    component: Raffle
  },
  {
    path: routeNames.adminAuction,
    title: 'Admin Auction Page',
    component: Admin
  },
  {
    path: routeNames.adminLottery,
    title: 'Admin Lottery Page',
    component: AdminLottery
  },
  {
    path: routeNames.adminMystery,
    title: 'Admin Mystery Page',
    component: AdminMystery
  },
  {
    path: routeNames.adminDatabase,
    title: 'Admin Database Page',
    component: AdminDatabase
  },
  {
    path: routeNames.adminRaffle,
    title: 'Admin Raffle Page',
    component: AdminRaffle
  },
  {
    path: routeNames.myAccount,
    title: 'My Account',
    component: MyAccount
  },
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • MultiversX ${dAppName}`
    : `MultiversX ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent
  };
});
