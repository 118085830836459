import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import * as config from '../../config';
import { Layout } from 'components/Layout/Layout';
import { RaffleContractDetails } from './RaffleContractDetails';
import { RaffleCreateContractDetails } from './RaffleCreateContractDetails';
import { TransactionsCard } from './TransactionsCard';
import { TicketsContractDetails } from './TicketsContractDetails';

export const AdminRaffle = () => {
  const { address } = useGetAccount();

  if(address.toString() === config.adminAddress){
    return (
      <Layout>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h1 className='mt-5'>
                <strong>Raffle admin panel</strong>
              </h1>
            </div>

            <div className='col-lg-6 col-sm-12 mt-5'>
              <TicketsContractDetails />

              <div className='mt-5'>
                <RaffleCreateContractDetails />
              </div>
            </div>
            
            <div className='col-lg-6 col-sm-12 mt-5'>
              <RaffleContractDetails />
            </div>

            <div className='col-12 mt-5'>
              <TransactionsCard />
            </div>            
          </div>
        </div>
      </Layout>
    );
  }else{
    return (
      <Layout>
        <p className='p-3 mt-3 text-center' style={{background: 'rgb(18,18,18'}}>Page visible to admin only</p>
      </Layout>
    );
  }
};
