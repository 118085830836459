import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useRaffleDeleteRound } from 'contexts/hooks/transactions/useRaffleDeleteRound';
import { useRaffleDrawRound } from 'contexts/hooks/transactions/useRaffleDrawRound';
import * as scRequests from '../../contexts/scRaffleRequests';
import 'react-datepicker/dist/react-datepicker.css';
import { Row, Col } from 'react-bootstrap';
import { getDatabase, ref as databaseRef, set, remove, get } from 'firebase/database';

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const getAllRafflePrizes = async (): Promise<any[]> => {
  const database = getDatabase();

  try {
    const rafflePrizesRef = databaseRef(database, 'rafflePrizes');
    const snapshot = await get(rafflePrizesRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      const prizesArray = Object.keys(data).map(key => data[key]);
      return prizesArray;
    } else {
      console.log('No raffle prizes found');
      return [];
    }
  } catch (error) {
    console.error('Error fetching raffle prizes:', error);
    return [];
  }
};

interface Prize {
  type: 'tokens' | 'sfts';
  identifier: string;
  amount: number;
  nonce: number;
  imageUrl: string;
}

export const RaffleContractDetails = () => {
  const [raffle, setRaffle] = useState({id: 0, slots: 0, start_time: 0, end_time: 0});
  const [entries, setEntries] = useState(0);
  const [uniqueEntries, setUniqueEntries] = useState(0);
  const [prizes, setPrizes] = useState([{}]);
  const [raffleId, setRaffleId] = useState<number>(1);

  const getRaffles = async () => {
    await scRequests.getRaffles().then(async (id: any) => {
      if(id.length > 0){
        const raffleData = await scRequests.getRaffleInfo(id.slice(-1));
        if(raffleData){
          setRaffle(raffleData);
        }

        const prizesData = await scRequests.getRafflePrizes(id.slice(-1));
        if(prizesData){
          setPrizes(prizesData);
        }

        const entriesData = await scRequests.getRaffleEntries(id.slice(-1));
        if(entriesData){
          setEntries(entriesData.length);        

          // Create a set to store unique values
          const uniqueBech32Set = new Set(entriesData.map((entry: any) => entry.toString()));

          setUniqueEntries(uniqueBech32Set.size);
        }
      }
    });
  };
  
  useEffect(() => {
    getRaffles();
    const interval = window.setInterval(() => {
      getRaffles();
		}, 2000);
		return () => window.clearInterval(interval);
	}, []);

  // get prize images from db
  const [prizeImages, setPrizeImages] = useState<Prize[]>([]);
  const fetchPrizes = async () => {
    const prizesData = await getAllRafflePrizes();
    setPrizeImages(prizesData);
  };
  useEffect(() => {
    fetchPrizes();
  }, []);

  return (
    <>
      <div className='card dapp-core-component__transactionsTable-styles__transactions-table mb-2'>
        <div className='card-header'>Raffle - Current Round Config</div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <strong>ID</strong>
            <span>{raffle?.id.toString()}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Slots</strong>
            <span>{raffle?.slots.toString()}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Start Time</strong>
            <span>{raffle?.start_time ? formatDate(raffle.start_time* 1000) : ''}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>End Time</strong>
            <span>{raffle?.end_time ? formatDate(raffle.end_time * 1000) : ''}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Tickets</strong>
            <span>{entries.toString()}</span>
          </div>
          <div className='my-3' style={{borderBottom: '1px dashed gray'}}/>

          <div className='d-flex justify-content-between align-items-center'>
            <strong>Users</strong>
            <span>{uniqueEntries.toString()}</span>
          </div>
          
          <Row>
            {prizes.length > 0 && (
              <>
                {prizes.map((prize: any, index) => (
                  <Col xs={12} lg={12} key={index} className='mt-3'>
                    <div className='p-2' style={{border: '1px dashed gray'}}>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='me-2' style={{width : '100%'}}>
                          <strong>Prize {(index+1).toString()}</strong>
                          <div className='d-flex justify-content-between align-items-center'>
                            <u>Type</u>
                            {prize?.token_nonce ? (
                              <span>{prize.token_nonce == 0 ? 'Tokens' : 'SFTS / NFTS'}</span>
                            ):('')}
                          </div>
                          <div className='d-flex justify-content-between align-items-center'>
                            <u>Identifier</u>
                            <span>{prize?.token_identifier}</span>
                          </div>
                          <div className='d-flex justify-content-between align-items-center'>                        
                            {prize?.token_nonce ? (
                              <>
                                <u>{prize.token_nonce == 0 ? 'Decimals' : 'Nonce'}</u>
                                <span>{prize.token_nonce == 0 ? '18' : prize.token_nonce.toString()}</span>
                              </>                          
                            ):('')}
                          </div>
                          <div className='d-flex justify-content-between align-items-center'>
                            <u>Amount</u>
                            {prize?.token_nonce ? (
                              <span>{prize.token_nonce == 0 ? new BigNumber(prize?.amount).shiftedBy(-18).toFixed() : prize.amount.toString()}</span>
                            ):('')}
                          </div>
                        </div>
                        <div>
                          <img src={prizeImages[index]?.imageUrl} style={{maxWidth: '150px', borderRadius: '10px'}}/>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Row>

          <div className='d-flex justify-content-between align-items-center mt-2'>
            <strong>Winners / Delete Round Id</strong>
            <span>
              <input
                type="number"
                id="raffleId"
                className="form-control"
                value={raffleId}
                onChange={e => setRaffleId(parseInt(e.target.value, 10))}
                min="0"
              />
            </span>
          </div>

        </div>
        <div className='card-footer d-flex justify-content-between'>
          <button className='btn btn-primary' onClick={useRaffleDrawRound(raffleId)}>
            Draw Winners
          </button>
          <button className='btn btn-danger' onClick={useRaffleDeleteRound(raffleId)}>
            Delete Round
          </button>
        </div>
      </div>      
    </>
  );
};
