import styles from '../raffle.module.scss';
import Countdown from 'react-countdown';

export default function Timer({ startTimestamp }:{startTimestamp: number}) {

	// build the countdown timer
	const renderer = ({ days, hours, minutes, seconds, completed }: {days: any, hours: any, minutes: any, seconds: any, completed: boolean}) => {
		if (completed) {
			return (
				''
			);
		} else {
			return (
				<div className="text-center mt-4 mb-3" style={{border: '1px solid #444781'}}>
					<p className={`${styles.timerTitle} mt-4`}>The raffle ends in:</p>
					<div className='d-flex justify-content-center mt-2'>
						<p className={`${styles.timerDigits}`}>{String(days).padStart(2, '0')} D</p>
						<p className={`${styles.timerDelimiter}`}> | </p>
						<p className={`${styles.timerDigits}`}>{String(hours).padStart(2, '0')} h</p>
						<p className={`${styles.timerDelimiter}`}> | </p>
						<p className={`${styles.timerDigits}`}>{String(minutes).padStart(2, '0')} m</p>
						<p className={`${styles.timerDelimiter}`}> | </p>
						<p className={`${styles.timerDigits}`}>{String(seconds).padStart(2, '0')} s</p>
					</div>
				</div>
			);
		}
	};

	return (
		<Countdown renderer={renderer} date={startTimestamp} autoStart/>
	);
}